<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Преподаватель
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <div class="row">
              <div class="col-lg-12">
                <TeacherPersonalData :teacher-id="teacherId"></TeacherPersonalData>
              </div>
              <div class="col-lg-12">
                <b-tabs pills card>
                  <b-tab>
                    <template v-slot:title>
                      Классное руководство
                    </template>
                    <TeacherClassRooms :teacher-id="teacherId"></TeacherClassRooms>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      Предметы
                    </template>
                    <TeacherSubjects :teacher-id="teacherId"></TeacherSubjects>
                  </b-tab>   
                  <b-tab>
                    <template v-slot:title>
                      Расписание
                    </template>
                    <TeacherTimeTable :teacher-id="teacherId"></TeacherTimeTable>
                  </b-tab>  
                  <b-tab @click="reloadTeacherNotWorkingHours">
                    <template v-slot:title>
                      Нерабочие часы
                    </template>
                    <TeacherNotWorkingHours :teacher-id="teacherId" ref="teacherNotWorkingHours"></TeacherNotWorkingHours>
                  </b-tab>
                </b-tabs>
              </div>              
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TeacherPersonalData from "./partials/TeacherPersonalData.vue";
import TeacherClassRooms from "./partials/TeacherClassRooms.vue";
import TeacherSubjects from "./partials/TeacherSubjects.vue";
import TeacherTimeTable from "./partials/TeacherTimeTable.vue";
import TeacherNotWorkingHours from "./partials/TeacherNotWorkingHours.vue";

export default {
  name: "emptySample",
  components: {
    KTPortlet,
    TeacherPersonalData,
    TeacherClassRooms,
    TeacherSubjects,
    TeacherTimeTable,
    TeacherNotWorkingHours,
  },
  data() {
    return {
      teacherId: this.$route.params.id,
    };
  },
  mounted() {
   
  },
  methods: {
    reloadTeacherNotWorkingHours(){      
      this.$refs['teacherNotWorkingHours'].reloadData();
    }
  }
};
</script>
