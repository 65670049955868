<template>
  <table class="table table-bordered">
    <thead>
    <tr>
      <td>Фио</td>
      <td>{{teacher.firstName}} {{teacher.name}} {{teacher.parentName}}</td>
    </tr>
    <tr>
      <td>Номер телефона</td>
      <td>{{teacher.contactPhone}}</td>
    </tr>
    <tr>
      <td>ИНН</td>
      <td>{{teacher.inn}}</td>
    </tr>
    <tr>
      <td>Логин</td>
      <td>{{teacher.userName}}</td>
    </tr>
    </thead>

  </table>
</template>

<style>

</style>


<script>
import ApiService from "@/common/api.service";

export default {
  name: "TeacherPersonalData",
  components: {    
  },
  props:{
    teacherId:String
  },
  data() {
    return {
      teacher:{
        firstName:'',
        name:'',
        parentName:'',
        contactPhone:'',
        userName:'',
      }
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData(){
      let $this = this;
      ApiService.querySecured("teachers/find", {
        params: {id: this.teacherId}
      })
          .then(({data}) => {
            $this.teacher = data;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
  }
};
</script>
