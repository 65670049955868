<template>
  <table class="table table-bordered">
    <thead class="thead-light">
    <tr>
      <th class="text-left">
        {{ $t("groupSubjects.list.group") }}
      </th>
      <th class="text-left">
        {{ $t("groupSubjects.list.subject") }}
      </th>
      <th class="text-left">
        {{ $t("groupSubjects.list.teacher") }}
      </th>
      <th class="text-left"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in subjects" :key="item.id">
      <td>{{ item.groupName }}</td>
      <td>{{ item.subjectName }} {{ item.subgroupName }}</td>
      <td>{{ item.teacherName }}</td>
      <td>
        <router-link :to="{ name: 'marks', params: { id: getId(item.groupSubjectId) }}" class="btn btn-icon btn-success">
          <i class="flaticon2-arrow"></i>
        </router-link>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<style>

</style>


<script>
import ApiService from "@/common/api.service";

export default {
  name: "TeacherSubjects",
  components: {    
  },
  props:{
    teacherId:String
  },
  data() {
    return {
      subjects:[]
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData(){
      let $this = this;
      ApiService.querySecured("groupSubjects/getForTeacher", {
        params: {teacherId: this.teacherId}
      })
          .then(({data}) => {
            $this.subjects = data;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    getId(groupSubject) {
      if(groupSubject.groupSubjectSubGroupId != null){
        return "sub-" + groupSubject.groupSubjectSubGroupId;
      }else{
        return groupSubject.groupSubjectId;
      }
    }
  }
};
</script>
