<template>
  <div class="row">
    <div class="col-lg-12">
      <b-form-datepicker id="example-datepicker" v-model="date" class="mb-2" @context="reloadData"
                         :date-disabled-fn="dateDisabled"
                         start-weekday="1"
                         locale="ru"></b-form-datepicker>
    </div>
    <div class="col-lg-12">
      <v-sheet height="600">
        <v-calendar
            ref="calendar"
            :value="date"
            :events="events"
            :type="type"
            color="primary"
            locale="ru"
            weekdays="1, 2, 3, 4, 5, 6"
            first-interval="7"
            interval-count="12"
        ></v-calendar>
      </v-sheet>
    </div>
  </div>
</template>

<style>
.v-event-timed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.v-event-timed.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0;
}
</style>


<script>
import ApiService from "@/common/api.service";

export default {
  name: "TeacherTimeTable",
  components: {    
  },
  props:{
    teacherId:String
  },
  data() {
    return {
      date: new Date(),
      type: 'week',
      events: [],
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0;
    },
    reloadData() {
      let $this = this;
      let date = this.date;
      if (date == false) {
        date = new Date();
        this.date = date;
      }

      ApiService.querySecured("events/getForTeacher", {
        params: {date: this.date, teacherId:this.teacherId}
      })
          .then(({data}) => {
            $this.events = data.events;

            console.log("asas");
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    changeDate() {
      this.reloadData()
    }
  }
};
</script>
