<template>
  <div :reDrawTable="reDrawTable">
    <div v-for="shift in shifts" :key="shift">
      <h1>{{shiftVal(shift)}}</h1>
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left">#</th>
          <th class="text-left" v-for="day in days" :key="day">
            {{ $t("schoolTimeTable.day." + day) }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="dayItem in dayItems" :key="dayItem">
          <td>{{ dayItem }}</td>
          <td v-for="day in days" :key="day">
            <button class="btn btn-outline-primary"  v-if="showAddButton(shift, day, dayItem)" @click="addItem(shift, day, dayItem)">
              <i class="fa fa-plus"></i> {{ $t("common.add") }}
            </button>
            <button class="btn btn-outline-danger"  v-if="!showAddButton(shift, day, dayItem)" @click="deleteItem(shift, day, dayItem)">
              <i class="fa fa-plus"></i> {{ $t("common.delete") }}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>


</template>

<style>

</style>


<script>
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "TeacherNotWorkingHours",
  components: {    
  },
  props:{
    teacherId:String
  },
  data() {
    return {
      shifts: [/*1, 2*/],
      dayItems: DictionariesService.dayItems,
      days: DictionariesService.days,
      data:{},
      reDrawTable: 0
    };
  },
  mounted() {
    //this.reloadData();
  },
  methods: {
    reloadData(){
      let $this = this;
      //this.shifts = [];
      ApiService.querySecured("schoolTimeTable/teacherWorkingSchedule", {
        params: {teacherId: this.teacherId}
      })
          .then(({data}) => {
            
            let shifts = [1, 2, 3];

            for (let shiftIndex in shifts){
              let shift = shifts[shiftIndex];
              
              $this.data[shift] = {
                days:{
                  1:{},
                  2:{},
                  3:{},
                  4:{},
                  5:{},
                  6:{}
                }
              };
            }
            
            for (let dataIndex in data){
              let dataItem  = data[dataIndex];
              $this.data[dataItem.shift]['days'][dataItem.day][dataItem.lessonNumber] = dataItem.id;
            }

            
            $this.shifts = shifts;
            $this.forceReDraw();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },

    shiftVal(shift) {
      let shiftVal = '';
      switch (shift) {
        case 1:
          shiftVal = 'Первая';
          break;
        case 2:
          shiftVal = 'Вторая';
          break;
        case 3:
          shiftVal = 'Третья';
          break;
      }

      return shiftVal;
    },
    showAddButton(shift, day, dayItem){
      
      if(typeof this.data[shift]['days'][day] != 'undefined' 
          && typeof this.data[shift]['days'][day][dayItem] != 'undefined'
      ){
        return false;
      }
      
      return true;
    },
    addItem(shift, day, dayItem){
      let data = {
        shift:shift,
        day:day,
        lessonNumber: dayItem,
        teacherId: parseInt(this.teacherId)
      }
      let $this = this;
      ApiService.postSecured("schoolTimeTable/saveTeacherNotWorkingHour", data)
          .then(function () {
            
            $this.loading = false;

            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    deleteItem(shift, day, dayItem){
      let $this = this;
      let data = {
        id: this.data[shift]['days'][day][dayItem]
      }
      ApiService.postSecured("schoolTimeTable/deleteTeacherNotWorkingHour", data)
          .then(function () {
            
            $this.loading = false;

            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    forceReDraw() {
      this.reDrawTable = Math.random();
    },
  }
};
</script>
